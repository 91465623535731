import React from "react";
import "./plantationsMaincontent.css";

import {
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";

const PlantationsMaincontent = () => {
  return (
    <ParallaxProvider>
      <div>
        <div className="responsive-plantaion">
          <h1 className="bigHugeTitle">
            {" "}
            <div style={{ textAlign: "right" }}>...</div>{" "}
          </h1>
        </div>
        <div className="plantaion-desktop">
          <div className="responsive-plantaion">
            <ParallaxBanner style={{ aspectRatio: "2 / 1" }}>
              <ParallaxBannerLayer
                image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/plant/mid-banner.webp"
                speed={10}
              />
            </ParallaxBanner>
          </div>
        </div>
        <div className="plantaion-mobile">
          <div className="responsive-plantaion">
            <ParallaxBanner style={{ aspectRatio: "auto 360 / 540" }}>
              <ParallaxBannerLayer
                image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/plant/plant-2.webp"
                speed={10}
              />
            </ParallaxBanner>
          </div>
        </div>
        <div className="responsive-plantaion">
          <p className="para-plantaion">
            As one of Sri Lanka's highest yielding rubber plantation companies
            and a pioneer in rubber manufacturing in South Asia, our commitment
            to the highest standards of quality forms the backbone of our
            operations
            <br />
            <br />
            Lalan Rubbers' agri division manages approximately 17,500 acres of
            plantations comprising rubber, coconut, tea, cinnamon, and forestry.
            Minor holdings of crops such as cassava, ginger, pineapple, and
            turmeric are also maintained to conform to the emerging requirements
            under the National Policy Framework of Vistas of Prosperity and
            Splendour.
            <br />
            <br />
            A special point of pride is the recognition of our high standards of
            agricultural practices, and training and development of our
            employees - which has been key to attracting and retaining team
            members by way of providing relatively more stable and higher
            earnings throughout the years. We have been able to maintain a
            sustainable competitive advantage by investing in the renewal of the
            capital assets timely and consistently and undertaking crop
            diversification.
            <br />
            <br />
            We have undertaken best management practices in agriculture whilst
            conforming to social, environmental, and economic considerations
            that are audited and certified according to the standards set by the
            Forest Stewardship Council&trade;, Germany, through an independent
            certification body. We have been awarded the FSC&trade; certificate
            for ten successive years since 2010.
            <br />
            <img
              className="fsc-image"
              src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/plant/fsc_logo_2-2.png"
              style={{ marginTop: "50px" }}
            ></img>
            <br />
            <div className="para-plantaion-link">
              FSC&trade;-certified products are available upon request as the
              website is common for the group:
              <a href="https://www.lalangroup.com/" className="para-plantaion">
                {" "}
                www.lalangroup.com
              </a>
            </div>
            <br />
            <br />
            Our Central Rubber Nursery (CRN), the largest in Sri Lanka, is
            certified by the Rubber Development Department of Sri Lanka, and
            supports hundreds of rubber smallholders by providing them with
            plants for their planting programmes and reinforcing these
            programmes with buy-back guarantees
            <br />
            <br />
          </p>
        </div>
        <div className="responsive-plantaion">
          <p className="address">
            Lalan Rubbers (Private) Limited <br />
            54, Kirulapone Avenue, <br />
            Colombo 06, <br />
            Sri Lanka. <br />
            info@lalangroup.com <br />
            (+94) 11 574 4555 <br />
            <br />
          </p>
        </div>
        <br /> <br />
        <div className="responsive-plantaion">
          <div className="blue-line-responsive">
            <center>
              <h1 className="bigHugeTitle-pc">
                9 Sectors <br />
                of Integrated <br />
                <span
                  style={{
                    color: "#46708a",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Expertise
                </span>
              </h1>
              <div>
                <div style={{ marginTop: "100px", marginBottom: "250px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "200px",
                    }}
                  >
                    <div style={{ width: "2px", background: "#46708a" }}></div>
                  </div>
                </div>
              </div>
            </center>
          </div>
          <div>
            <div>
              <p className="text-max-plantation">
                9 Sectors of Integrated
                <span
                  style={{
                    color: "#46708a",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Expertise
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default PlantationsMaincontent;
